import UnitTranslationSelectorPlugin from '@edx/unit-translation-selector-plugin';
import { DatadogLoggingService } from '@edx/frontend-logging';
import { LockPaywall, UpgradeNotification, UpgradeModalWrapper } from '@edx/frontend-plugin-advertisements';
import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';

// Load environment variables from .env file
const config = {
  ...process.env,
  AI_TRANSLATIONS_URL: 'https://ai-translations.edx.org',
  loggingService: DatadogLoggingService,
  OPTIMIZELY_FULL_STACK_SDK_KEY: 'B1ha73pEGEG2rbcVZSRGj',
  // [AU-2108] VTR In-Course Popup Modal
  OPTIMIZELY_MODAL_ROLLOUT_WHITELIST: [
    "edX",
    "edx"
  ],
  pluginSlots: {
    unit_title_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'unit_title_widget',
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: UnitTranslationSelectorPlugin,
          },
        },
      ],
    },
    sequence_container_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: "sequence_container_widget",
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: () => <div id="whole-course-translation-feedback-widget" />,
          },
        },
      ],
    },
    gated_unit_content_message_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'gated_unit_content_message_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: LockPaywall,
          },
        },
      ],
    },
    notification_widget_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'notification_widget_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: UpgradeNotification,
          },
        },
      ],
    },
    notification_tray_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'notification_tray_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: UpgradeNotification,
          },
        },
      ],
    },
    outline_tab_notifications_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'outline_tab_notifications_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: UpgradeNotification,
          },
        },
      ],
    },
    next_button_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'next_button_slot',
            type: DIRECT_PLUGIN,
            priority: 50,
            RenderWidget: UpgradeModalWrapper,
          },
        },
      ],
    },
  },
};

export default config;
